import React, { useState, useEffect } from "react";
import SongList from "./components/SongList/SongList";
import "./App.css";

const radioOptions = [
  {
    radioName: "kiyi_muzik",
    shortCode: "kiyimuzik",
    label: "Kıyı Müzik",
    url: "https://kiyi.radioca.st/stream",
  },
  {
    radioName: "kiyi_muzik_caz",
    shortCode: "kiyimuzikcaz",
    label: "Kıyı Müzik Caz",
    url: "https://caz.radioca.st/stream",
  },
  {
    radioName: "kiyi_gece_muzik",
    shortCode: "kiyigecemuzik",
    label: "Kıyı Gece Müzik",
    url: "https://gece.radioca.st/stream",
  },
  {
    radioName: "kiyi_muzik_turk",
    shortCode: "kiyimuzikturk",
    label: "Kıyı Müzik Türk",
    url: "https://kiyiturk.radioca.st/stream",
  },
  {
    radioName: "standart.fm",
    shortCode: "standartfm",
    label: "Standart FM",
    url: "https://moondigitaledge.radyotvonline.net/standartfm/playlist.m3u8",
  },
  {
    radioName: "radyo_eksen",
    shortCode: "radyoeksen",
    label: "Radyo Eksen",
    url: "https://dygedge.radyotvonline.net/radyoeksen/playlist.m3u8",
  },
  {
    radioName: "loungefm",
    shortCode: "loungefm",
    label: "Lounge FM",
    url: "https://edge1.radyotvonline.net/shoutcast/play/loungefm",
  },
  {
    radioName: "radyovoyage",
    shortCode: "radyovoyage",
    label: "Radyo Voyage",
    url: "https://dygedge.radyotvonline.net/radyovoyage/playlist.m3u8",
  },
];

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(radioOptions[0]); // Varsayılan ilk radyo
  const [showRadioSelector, setShowRadioSelector] = useState(false); // Radyo seçiciyi aç/kapat

  const handleRadioChange = (e) => {
    const radio = radioOptions.find((r) => r.radioName === e.target.value);
    setSelectedRadio(radio);
    setShowRadioSelector(false); // Kanal değiştirildiğinde kapat
  };
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);
  useEffect(() => {
    // URL'yi okuyarak seçili radyoyu belirle
    const path = window.location.pathname.replace("/", ""); // URL'den radyo adını al
    const matchedRadio = radioOptions.find((radio) => radio.shortCode === path);
    if (matchedRadio) {
      setSelectedRadio(matchedRadio); // URL'ye uygun radyoyu seç
    }
  }, []);
  const toggleRadioSelector = () => {
    setShowRadioSelector((prev) => !prev);
  };
  return (
    <div className="App">
      {isMobile && showRadioSelector && (
        <div className="radio-selector">
          <label htmlFor="radio-select">Radyo Seç:</label>
          <select
            id="radio-select"
            onChange={handleRadioChange}
            value={selectedRadio.radioName}
          >
            {radioOptions.map((radio) => (
              <option key={radio.radioName} value={radio.radioName}>
                {radio.label}
              </option>
            ))}
          </select>
        </div>
      )}
      {isMobile ? (
        <div className="grid-container">
          <SongList
            isMobile={isMobile}
            key={selectedRadio.radioName}
            title={selectedRadio.label}
            radioName={selectedRadio.radioName}
            url={selectedRadio.url}
            onEditTitle={toggleRadioSelector} // Radyo değiştirme açılır menüsünü kontrol et
          />
        </div>
      ) : (
        <div className="grid-container">
          {radioOptions.map((radio) => (
            <SongList
              isMobile={isMobile}
              key={radio.radioName}
              title={radio.label}
              radioName={radio.radioName}
              url={radio.url}
              onEditTitle={toggleRadioSelector} // Radyo değiştirme açılır menüsünü kontrol et
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
