// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-container_filter {
    margin: 10px 10px 10px 10px;
}

label {
    margin-right: 10px;
}
select {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f9f9f9;
    color: #333;
    transition: border-color 0.3s ease;
}

select:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/FilterTypeSelector/FilterTypeSelector.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,kCAAkC;AACtC;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".select-container_filter {\n    margin: 10px 10px 10px 10px;\n}\n\nlabel {\n    margin-right: 10px;\n}\nselect {\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 14px;\n    background-color: #f9f9f9;\n    color: #333;\n    transition: border-color 0.3s ease;\n}\n\nselect:focus {\n    border-color: #007bff;\n    outline: none;\n    background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
