import React from "react";
import "./SongItem.css";

function SongItem({ song, songType }) {
  const truncateText = (text, maxLength = 38) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <li className="song-item">
      <img
        src={song.cover_url}
        alt={song.title}
        className="album-cover"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "default_cover_url";
        }}
      />
      <a
        href={song.url}
        target="_blank"
        rel="noopener noreferrer"
        className="track-link"
      >
        <div className="song-info">
          {["titles", "date"].includes(songType) && (
            <div>
              <strong>Çalma Zamanı:</strong>{" "}
              {new Date(song.date).toLocaleString()}
            </div>
          )}
          {songType === "most-played" && (
            <div>
              <strong>Çalma Sayısı:</strong> {song.playCount}
            </div>
          )}
          <div>
            <strong>Şarkı İsmi:</strong> {truncateText(song.title)}
          </div>
          <div>
            <strong>Sanatçı:</strong> {truncateText(song.artist)}
          </div>
        </div>
      </a>
    </li>
  );
}

export default SongItem;
