import React from "react";
import "./SongTypeSelector.css";

function SongTypeSelector({ songType, onChange }) {
  return (
    <div className="select-container">
      <select value={songType} onChange={(e) => onChange(e.target.value)}>
        <option value="titles">Son Çalınan Şarkılar</option>
        <option value="most-played">En Çok Çalınan Şarkılar</option>
        <option value="genre">Türüne Göre Şarkılar</option>
        <option value="date">Belirli Bir Günün Şarkıları</option>{" "}
        {/* Yeni seçenek eklendi */}
      </select>
    </div>
  );
}

export default SongTypeSelector;
