import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import SongItem from "../SongItem/SongItem";
import GenreSelector from "../GenreSelector/GenreSelector";
import SongTypeSelector from "../SongTypeSelector/SongTypeSelector";
import FilterTypeSelector from "../FilterTypeSelector/FilterTypeSelector";
import DateSelector from "../DateSelector/DateSelector"; // Yeni import
import { ReactComponent as RefreshIcon } from "../../assets/refreshIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icon_adjudgement.svg";

import "./SongList.css";

function SongList({ title, radioName, onEditTitle, isMobile }) {
  const [songs, setSongs] = useState([]);
  const [error, setError] = useState(null);
  const [songType, setSongType] = useState("titles");
  const [filterType, setFilterType] = useState("week");
  const [genre, setGenre] = useState("");
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Yalnızca YYYY-MM-DD formatını alır
  });
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(300);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0); // Toplam veri sayısı

  const fetchSongs = async () => {
    setLoading(true);
    try {
      let endpoint = `titles/${radioName}?limit=20&page=1`;
      if (songType === "most-played") {
        endpoint = `most-played/${radioName}?period=${filterType}&limit=${limit}&page=${offset}`;
      } else if (songType === "genre" && genre) {
        endpoint = `genre/${radioName}?genre=${genre}&limit=${limit}&page=${offset}`;
      } else if (songType === "date" && date) {
        endpoint = `date/${radioName}?date=${date}&limit=${limit}&page=${offset}`;
      }

      const response = await fetch(`${API_URL}${endpoint}`);
      if (!response.ok) {
        throw new Error("Veri ekilemedi");
      }
      const data = await response.json();
      setSongs(data.results);
      setTotal(data.pagination.totalDocuments); // Toplam veri sayısını al
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleEditTitle = () => {
    if (onEditTitle) {
      onEditTitle(); // App.js'deki toggleRadioSelector fonksiyonunu çağır
    }
  };
  useEffect(() => {
    fetchSongs();
    const interval = setInterval(fetchSongs, 4 * 60 * 1000);
    return () => clearInterval(interval);
  }, [radioName, songType, filterType, genre, date]);

  const handleRefresh = () => {
    fetchSongs();
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <div>Yükleniyor...</div>
      </div>
    );
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }
  const songListInnerHeight = songType === "titles" ? "1256px" : "auto"; //

  return (
    <div className="song-list">
      <div className="header">
        <RefreshIcon onClick={handleRefresh} className="refresh-icon" />
        <h2 className="title-with-icon">
          {title}
          {isMobile && (
            <span className="edit-icon">
              <SettingsIcon
                onClick={handleEditTitle}
                className="settings-icon"
              />
            </span>
          )}
        </h2>
      </div>
      <div className="selector-container">
        <SongTypeSelector songType={songType} onChange={setSongType} />

        {songType === "most-played" && (
          <FilterTypeSelector
            filterType={filterType}
            onChange={setFilterType}
            className="selector-group"
          />
        )}
        {songType === "genre" && (
          <GenreSelector
            genre={genre}
            onChange={setGenre}
            className="selector-group"
          />
        )}
        {songType === "date" && (
          <DateSelector date={date} onChange={setDate} /> // Yeni bileşeni kullan
        )}
      </div>

      <ul
        className="song-list-inner"
        style={{ maxHeight: songType === "titles" ? "1256px" : "1206px" }}
      >
        {songs.map((song, index) => (
          <SongItem key={index} song={song} songType={songType} />
        ))}
      </ul>
    </div>
  );
}

export default SongList;
