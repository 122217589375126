import React from "react";
import "./DateSelector.css";

function DateSelector({ date, onChange }) {
  return (
    <div className="select-container-date">
      <label htmlFor="date">Tarih: </label>
      <input
        id="date"
        type="date"
        value={date}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default DateSelector;
