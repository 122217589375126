import React from "react";
import "./FilterTypeSelector.css";

function FilterTypeSelector({ filterType, onChange }) {
  return (
    <div className="select-container">
      <label htmlFor="filterType">Filtreleme Dönemi:</label>
      <select
        id="filterType"
        value={filterType}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="week">Hafta</option>
        <option value="month">Ay</option>
        <option value="year">Yıl</option>
      </select>
    </div>
  );
}

export default FilterTypeSelector;
