import React from "react";
import "./GenreSelector.css";

function GenreSelector({ genre, onChange }) {
  const genres = [
    "African",
    "Alternative",
    "Ambient",
    "Blues",
    "Classical",
    "Classical Crossover",
    "Contemporary Jazz",
    "Dance",
    "Disco",
    "Downtempo",
    "Electronic",
    "Electronica",
    "Folk",
    "French Pop",
    "Funk",
    "Hard Rock",
    "Hip-Hop",
    "Hip-Hop/Rap",
    "House",
    "Indie Pop",
    "Indie Rock",
    "Instrumental",
    "Jazz",
    "Latino",
    "Metal",
    "New Age",
    "Pop",
    "Pop in Spanish",
    "Pop/Rock",
    "Prog-Rock/Art Rock",
    "Psychedelic",
    "Punk",
    "R&B/Soul",
    "Reggae",
    "Rock",
    "Singer/Songwriter",
    "Soul",
    "Soundtrack",
    "Techno",
    "Trance",
    "Turkish",
    "Turkish Pop",
    "Vocal",
    "World",
  ];

  return (
    <div className="select-container">
      <label htmlFor="genre">Tür Seçimi:</label>
      <select
        id="genre"
        value={genre}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Tür Seçiniz</option>
        {genres.map((g) => (
          <option key={g} value={g}>
            {g}
          </option>
        ))}
      </select>
    </div>
  );
}

export default GenreSelector;
